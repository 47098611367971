.wallet-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
}

.amount-input {
    padding: 10px;
    margin-bottom: 10px;
    width: 80%;
    border: 1px solid #007bff;
    border-radius: 5px;
    font-size: 16px;
}

.add-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.add-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}
