/* ComingSoon.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.coming-soon-container {
  min-height: 100vh;
  background: linear-gradient(135deg, #0f2027, #203a43, #2c5364);
  font-family: 'Poppins', sans-serif;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  overflow: hidden;
  position: relative;
}

/* Background animation */
.coming-soon-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255,255,255,0.05) 1px, transparent 1px);
  background-size: 30px 30px;
  z-index: 1;
}

.content-wrapper {
  max-width: 1000px;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.header {
  text-align: center;
  margin-bottom: 40px;
}

.header h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 10px;
  background: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: glow 1.5s ease-in-out infinite alternate;
}

.subheading {
  font-size: 1.5rem;
  font-weight: 300;
  opacity: 0.9;
}

/* Countdown styles */
.countdown-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}

.countdown-item {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  padding: 15px;
  width: 100px;
  text-align: center;
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.countdown-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.countdown-value {
  font-size: 2.5rem;
  font-weight: 700;
}

.countdown-label {
  font-size: 0.9rem;
  opacity: 0.8;
  margin-top: 5px;
}

/* Info section */
.info-section {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 25px;
  margin-bottom: 30px;
  backdrop-filter: blur(5px);
}

.info-section h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #4facfe;
}

.info-section p {
  line-height: 1.6;
  margin-bottom: 20px;
}

/* CTA Button */
.cta-button {
  display: inline-block;
  background: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  color: white;
  font-weight: 600;
  padding: 12px 30px;
  border-radius: 30px;
  text-decoration: none;
  margin-top: 10px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(79, 172, 254, 0.4);
  border: none;
  cursor: pointer;
  text-align: center;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 7px 20px rgba(79, 172, 254, 0.6);
}

.cta-button:active {
  transform: translateY(-1px);
}

/* Newsletter section */
.newsletter-section {
  margin-bottom: 30px;
  text-align: center;
}

.newsletter-section h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.subscribe-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 500px;
  margin: 0 auto;
}

.subscribe-form input {
  flex: 1;
  min-width: 200px;
  padding: 12px 15px;
  border-radius: 30px;
  border: none;
  background: rgba(255, 255, 255, 0.15);
  color: white;
  outline: none;
  backdrop-filter: blur(5px);
}

.subscribe-form input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.subscribe-form button {
  padding: 12px 25px;
  border-radius: 30px;
  border: none;
  background: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.subscribe-form button:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(79, 172, 254, 0.5);
}

/* Social Links */
.social-links {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
}

.social-icon:hover {
  background: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(79, 172, 254, 0.5);
}

/* Animations */
@keyframes glow {
  from {
    text-shadow: 0 0 5px #4facfe, 0 0 10px #4facfe;
  }
  to {
    text-shadow: 0 0 10px #4facfe, 0 0 20px #4facfe, 0 0 30px #4facfe;
  }
}

.animate-fade-in {
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.animate-slide-up {
  opacity: 0;
  transform: translateY(30px);
  animation: slideUp 0.8s forwards;
}

.delay-100 {
  animation-delay: 0.1s;
}

.delay-200 {
  animation-delay: 0.2s;
}

.delay-300 {
  animation-delay: 0.3s;
}

.delay-400 {
  animation-delay: 0.4s;
}

.delay-500 {
  animation-delay: 0.5s;
}

.delay-600 {
  animation-delay: 0.6s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .content-wrapper {
    padding: 30px 20px;
  }
  
  .header h1 {
    font-size: 2.8rem;
  }
  
  .subheading {
    font-size: 1.2rem;
  }
  
  .countdown-item {
    width: 80px;
  }
  
  .countdown-value {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .content-wrapper {
    padding: 25px 15px;
  }
  
  .header h1 {
    font-size: 2.2rem;
  }
  
  .subheading {
    font-size: 1rem;
  }
  
  .countdown-container {
    gap: 10px;
  }
  
  .countdown-item {
    width: 65px;
    padding: 10px;
  }
  
  .countdown-value {
    font-size: 1.6rem;
  }
  
  .countdown-label {
    font-size: 0.8rem;
  }
  
  .info-section h2 {
    font-size: 1.5rem;
  }
  
  .subscribe-form {
    flex-direction: column;
  }
  
  .subscribe-form input, 
  .subscribe-form button {
    width: 100%;
  }
}