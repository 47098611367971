.wallet-history-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.wallet-history-container h3 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.history-item {
  padding: 15px;
  margin-bottom: 15px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.history-item p {
  margin: 5px 0;
  font-size: 16px;
  color: #555;
}

.end-message {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  color: #777;
}

.wallet-history-container {
  width: 100%;
  padding: 16px;
}

.history-item {
  padding: 12px;
  border: 1px solid #ddd;
  margin-bottom: 8px;
  position: relative;
}

.history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dots-menu {
  cursor: pointer;
  position: relative;
}

.dots-menu:hover {
  color: #555;
}

.dropdown {
  position: absolute;
  top: 20px;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 8px;
  border-radius: 4px;
  z-index: 100;
}

.dropdown p {
  margin: 0;
  padding: 8px;
  cursor: pointer;
  color: #333;
}

.dropdown p:hover {
  background-color: #f0f0f0;
}
