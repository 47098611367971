/* Address List Container */
.address-list-container {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  background-color: #f7f9fc;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Address List */
.address-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  list-style: none;
  padding: 0;
}

/* Address Item (Compact Card) */
.address-item {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.address-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Address Text Details */
.address-item p {
  font-size: 14px;
  color: #495057;
  margin: 5px 0;
}

.address-item p strong {
  font-weight: bold;
  color: #333;
}

/* Default Address Badge */
.address-item::before {
  content: "Default";
  display: block;
  background-color: #007bff;
  color: white;
  font-size: 12px;
  padding: 3px 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 12px;
  display: none;
}

.address-item p::before {
  margin-right: 5px;
}

.address-item p:first-child:contains("Default address") + &::before {
  display: block;
}

/* Select Button */
.select-btn {
  background-color: #28a745;
  color: white;
  padding: 8px 12px’
}