.market-size-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    text-align: left;
  }
  
  .progress-bar {
    width: 100%;
    height: 8px;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-bottom: 20px;
    overflow: hidden;
  }
  
  .progress {
    height: 100%;
    background-color: #ff6600;
    transition: width 0.3s ease-in-out;
  }
  
  .navigation-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .prev-button,
  .next-button,
  .submit-button {
    background-color: #ff6600;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;
  }
  
  .prev-button:hover,
  .next-button:hover,
  .submit-button:hover {
    background-color: #e05500;
  }
  
  .submit-button {
    background-color: #008000;
  }
  
  .submit-button:hover {
    background-color: #006600;
  }
  