.work-habitats-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .section-title {
    font-size: 1.5em;
    margin-bottom: 15px;
  }
  
  .input-label {
    font-weight: bold;
    margin-top: 10px;
    display: block;
  }
  
  .checkbox-group,
  .radio-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 15px;
  }
  
  .meal-time-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .meal-time {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  input[type="radio"],
  input[type="time"] {
    margin-right: 10px;
  }
  