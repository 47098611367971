.welcome-container {
  text-align: center;
  padding: 50px;
  background: linear-gradient(135deg, #fdfbfb, #ebedee);
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 50px auto;
}

.welcome-title {
  font-size: 2rem;
  font-weight: bold;
  color: #ff6600;
  margin-bottom: 10px;
}

.welcome-subtext {
  font-size: 1.2rem;
  color: #444;
  margin-bottom: 20px;
}

/* Animation containers */
.meal-animation, .hygiene-animation {
  margin: 10px auto;
}

/* Media Queries */
@media (max-width: 768px) {
  .welcome-title {
      font-size: 1.5rem;
  }
  .welcome-subtext {
      font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .welcome-title {
      font-size: 1.2rem;
  }
  .welcome-subtext {
      font-size: 0.9rem;
  }
  .welcome-container {
      padding: 30px;
  }
}
