.spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    background-color: #cdedf6;
  }
  
  .spinner-circle {
    width: 50px;
    height: 50px;
    border: 5px solid #3498db;
    border-top: 5px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  .spinner-dots {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    width: 80px;
  }
  
  .spinner-dots div {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #3498db;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out both;
  }
  
  .spinner-dots div:nth-child(1) {
    animation-delay: -0.32s;
  }
  .spinner-dots div:nth-child(2) {
    animation-delay: -0.16s;
  }
  .spinner-dots div:nth-child(3) {
    animation-delay: 0s;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes bounce {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  