.history-container {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .history-list {
    list-style-type: none;
    padding: 0;
  }
  
  .history-item {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .history-item h3 {
    margin: 0 0 10px;
    color: #2a9d8f;
  }
  
  .history-item p {
    margin: 5px 0;
    color: #555;
  }
  
  .error {
    color: red;
    text-align: center;
  }
  