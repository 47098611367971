.home-plans-container {
    padding: 20px;
    text-align: start;
    overflow: hidden; /* Keeps content constrained in the container */
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    max-width: 700px;
    
  }
  
  .plans-slider {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto; /* Enable horizontal scrolling */
    gap: 20px;
    padding-bottom: 10px; /* Space to avoid content being cut off */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
  }
  
  .plans-slider::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers */
  }
  .plan-card h3{
    color: #cdedf6;
  }
  .plan-card {

    background-color: #044243;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(63, 69, 81, 0.16);
    padding: 10px;
    min-width: 250px; /* Defines fixed width for scrolling */
    min-height: 200px;
    text-align: left;
    transition: transform 0.3s ease;
    height: 200px;
    
  }
 
  
  .plan-card:hover {
    transform: scale(1.05);
  }
  .plan-card img {
    width: 100%;
    height: auto;
    border-radius: 4px;
    object-fit: cover;
    max-height: 120px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .plan-img-div{
    padding: 10px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  .plan-discount{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }