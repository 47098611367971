.single-plan-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .single-plan-container h2 {
    font-size: 1.8em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .single-plan-container p {
    font-size: 1em;
    color: #555;
    margin: 8px 0;
  }
  
  .subscribe-btn {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
  }
  
  .subscribe-btn:hover {
    background-color: #45a049;
  }
  .plan-card {
    width: 80%;
    background-color: #044243;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .log-sin{
    text-align: center;
  }