.recommendations-container {
    padding: 16px;
    border-bottom: 1px solid #ddd;
  }
  
  .section-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 12px;
  }
  
  .input-label {
    display: block;
    margin-top: 12px;
    font-weight: bold;
  }
  
  .suggestions-textarea {
    margin-top: 8px;
    padding: 12px;
    width: 100%;
    height: 150px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    resize: vertical;
  }
  