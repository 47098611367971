.home-blogs-container {
    padding: 20px;
    background-color: #cdedf6;
    border-radius: 8px;
    max-width: 1200px;
    margin: auto;
  }
  
  .blogs-header {
    font-size: 12px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .blogs-slider {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 16px;
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;
    padding-bottom: 10px;
  }
  
  .blogs-slider::-webkit-scrollbar {
    height: 8px;
  }
  
  .blogs-slider::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 8px;
  }
  
  .blog-card {
    background-color: #044243;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    min-width: 200px;
    max-width: 200px;
    height: 200px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .blog-card p {
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 10px;
  }
  
  .blog-card img {
    width: 100%;
    height: auto;
    border-radius: 4px;
    object-fit: cover;
    max-height: 120px;
  }
  