/* Header Container */
.header {
  background-color: #044243;
  text-align: center;
  padding: 3rem 0;
  position: relative;
  color: #f5f5f5;
}

.header-title {
  font-size: 2.5rem;
  color: #f5f5f5;
  position: relative;
  z-index: 1;
}

/* Bottom border container */
.bottom-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1rem;
  overflow: hidden;
  background: linear-gradient(90deg, #FF5722, #FFD700, #4CAF50, #FF5722);
  background-size: 200% 100%;
  animation: border-move 6s linear infinite;
}

/* Animation for the moving gradient border */
@keyframes border-move {
  0% { background-position: 0% 0; }
  100% { background-position: 200% 0; }
}

/* Responsive adjustments */
@media (max-width: 48rem) {
  .header-title {
    font-size: 2rem;
  }
}

@media (max-width: 30rem) {
  .header-title {
    font-size: 1.5rem;
  }
}
.text-11-1 {
  font-size: 1.8rem;
  font-weight: bold;
  background: linear-gradient(90deg, #FF5722, #FFD700, #4CAF50, #FF5722);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  animation: text-gradient 3s linear infinite;
}
