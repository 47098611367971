.dash-cont {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    min-height: 95vh;
}

.wallet {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #5ce1e6;
    border-radius: 12px;
    margin: 10px;
    /* text-align: center;
    margin-bottom: 20px; */
}

.action {
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent wrapping */
    margin-bottom: 20px;
}

.action-options {
    display: flex; /* Align options in a row */
    justify-content: space-around;
}

.action-option {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #044243;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.action-option:hover {
    background-color: #5ce1e6; /* Darker shade on hover */
}

.main {
    
    padding: 20px;
    border-radius: 5px;
    
    height: fit-content;
}
