.budget-container {
    padding: 16px;
    border-bottom: 1px solid #ddd;
  }
  
  .section-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 12px;
  }
  
  .input-label {
    display: block;
    margin-top: 12px;
    font-weight: bold;
  }
  
  .radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 4px;
  }
  
  .radio-group label {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  