/* Footer Container */
.footer {
    background-color: #044243;
    color: #fff;
    padding: 1.25rem 0;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 75rem; /* Equivalent to 1200px if root font-size is 16px */
    margin: 0 auto;
  }
  
  /* Footer Section */
  .footer-section {
    flex: 1;
    min-width: 12.5rem; /* Equivalent to 200px */
    padding: 0.625rem; /* Equivalent to 10px */
    text-align: center;
  }
  
  .footer-section h4 {
    font-size: 1.125rem; /* Equivalent to 18px */
    margin-bottom: 0.625rem; /* Equivalent to 10px */
  }
  
  .footer-section p {
    font-size: 0.875rem; /* Equivalent to 14px */
    margin: 0.313rem 0; /* Equivalent to 5px */
  }
  
  /* Social Links */
  .social-links {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  .social-links li {
    margin: 0 0.313rem; /* Equivalent to 5px */
  }
  
  .social-links a {
    color: #fff;
    text-decoration: none;
    font-size: 1rem; /* Equivalent to 16px */
  }
  
  .social-links a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 48rem) { /* Equivalent to 768px */
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-section {
      min-width: 100%;
      margin-bottom: 1.25rem; /* Equivalent to 20px */
    }
  }
  
  @media (max-width: 30rem) { /* Equivalent to 480px */
    .footer-section h4 {
      font-size: 1rem; /* Equivalent to 16px */
    }
  
    .footer-section p {
      font-size: 0.75rem; /* Equivalent to 12px */
    }
  
    .social-links a {
      font-size: 0.875rem; /* Equivalent to 14px */
    }
  }
  