.meal-preferences-container {
    padding: 16px;
    border-bottom: 1px solid #ddd;
  }
  
  .section-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 12px;
  }
  
  .input-label {
    display: block;
    margin-top: 12px;
    font-weight: bold;
  }
  
  .checkbox-group,
  .radio-group {
    margin-top: 8px;
  }
  
  .checkbox-group label,
  .radio-group label {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 8px;
  }
  
  .text-input {
    margin-top: 8px;
    padding: 8px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .radio-group {
    display: flex;
    flex-direction: column;
  }
  
  .radio-group label {
    margin-bottom: 8px;
  }
  