.pause-calendar {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
    margin-top: 20px;
  }
  
  .calendar-day {
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s, background-color 0.2s;
  }
  
  .calendar-day span {
    font-size: 16px;
    font-weight: bold;
  }
  
  .calendar-day small {
    font-size: 10px;
    color: #555;
  }
  
  .calendar-day.selected {
    background-color: #007bff;
    color: white;
    font-weight: bold;
  }
  
  .calendar-day:hover {
    transform: scale(1.1);
    background-color: #add8e6;
  }
  
  .calendar-info {
    margin-top: 20px;
    text-align: center;
  }
  
  .calendar-info p {
    margin: 5px 0;
  }
  
  .calendar-info ul {
    list-style: none;
    padding: 0;
  }
  
  .calendar-info ul li {
    font-size: 14px;
    color: #333;
  }
  
  .calendar-day {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
    cursor: pointer;
    background-color: #fff; /* Default color */
    transition: background-color 0.3s;
  }
  
  .calendar-day.paused {
    background-color: lightgreen; /* Light green for paused and newly selected dates */
  }
  
  .calendar-day.selected {
    background-color: lightgreen; /* Same light green for newly selected dates */
  }
  
  .calendar-day:hover {
    background-color: #f0f0f0; /* Slight hover effect */
  }
  .fetched {
    background-color: lightgreen !important;
  }
  